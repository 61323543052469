.not-found-header {
  font-weight: 700;
}

.not-found-contents {
  font-size: 30px;
  text-align: center;
  margin: 30px;
  height: 90vh;
}

.not-found-link {
  color: #8c3d75;
  font-weight: 600;
}

.not-found-img {
  max-height: 267px;
  max-width: 350px;
  margin: auto;
  height: 100%;
  width: 100%;
}