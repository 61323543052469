.hero-banner-wrapper {
  color: white;
  background-image: url("../images/chili-background-sm.png");
  background-size: contain;
}

.hero-banner {
  color: white;
  background-color: rgba(0, 0, 0, .7);
  padding: 30px;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.hero-banner-image {
  width: 100%;
  height: 100%;
  max-height: 350px;
  max-width: 672px;
  margin: auto;
}

.hero-banner-cta {
  border: solid 2px #258647;
  background-color: #258647;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 15px;
  margin: 30px auto 0 auto;
  max-width: 250px;
  text-decoration: none;
  transition: background-color .25s, color .25s;
}

.hero-banner-cta:hover {
  background-color: #FFFFFF;
  color: #000000;
  transition: background-color .25s, color .25s;
}

.content-row-wrapper {
  padding: 30px;
  /* background: linear-gradient(217deg, rgb(141 194 221), rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, rgb(35 133 71), rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, rgb(171 67 145), rgba(0,0,255,0) 70.71%) */
}

.content-row-wrapper-blue {
  background-color: rgba(141, 194, 221, .35);
}

.content-row-wrapper-black {
  background-color: rgb(0, 0, 0);
}

.content-row-wrapper-chilis {
  color: white;
  background-image: url("../images/chili-background-sm.png");
}

.content-row-wrapper-colors {
  padding: 30px;
  background: linear-gradient(217deg, rgb(141 194 221), rgba(255, 0, 0, 0) 70.71%),
    linear-gradient(127deg, rgb(35 133 71), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgb(171 67 145), rgba(0, 0, 255, 0) 70.71%)
}


.content-row {
  margin: auto;
  max-width: 1140px;
}

.content-row-padded {
  padding: 30px;
}

.content-row-darkened {
  background-color: rgba(0, 0, 0, .5);
}


.content-row-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-row-header {
  margin: 0;
  font-size: 50px;
}

.content-row-header-white {
  color: #FFFFFF;
}

.content-row-subheader {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.what-is-heated {
  display: flex;
  margin-top: 15px;
}

.what-is-heated-left {
  text-align: left;
  width: 50%;
  font-weight: 500;
  font-size: 24px;
}

.what-is-heated-right {
  text-align: left;
  width: 50%;
  display: flex;
  margin-left: 30px;
  align-self: center;
}

.heated-writeup {
  text-align: left
}

.heated-writeup-paragraph {
  font-size: 24px;
  margin-top: 0;
}

.highlighted-text {
  color: #8c3d75;
  font-weight: 700;
}

.what-is-heated-quote {
  margin-bottom: auto;
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 15px;
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}

.print-at-home-edition-container {
  display: flex;
  flex-direction: column;
}

.print-at-home-edition-header-container {
  display: flex;
  margin: auto;
  align-items: center;
}

.print-at-home-edition-header-image-right {
  height: 100px;
  animation: rotate-right 4s infinite linear;
}

@keyframes rotate-right {
  25% {
    transform: rotate(-20deg);
  }

  75% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.print-at-home-edition-header-image-left {
  height: 100px;
  animation: rotate-left 4s infinite linear;
}

@keyframes rotate-left {
  25% {
    transform: rotate(20deg);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.print-at-home-edition-header {
  margin: 0 15px 0 15px;
  font-weight: 700;
  font-size: 45px;
}

.print-at-home-edition-messaging-container {
  text-align: left;
  /* max-width: 800px; */
  margin: 15px auto 15px;
}

.print-at-home-edition-messaging {
  margin-top: 0;
  font-size: 24px;
}

.print-at-home-editions {
  display: flex;
  justify-content: space-around;
}

.print-at-home-edition {
  display: flex;
  flex-direction: column;
}

.low-ink-edition {
  display: flex;
  flex-direction: column;
}

.print-at-home-cta {
  border: solid 2px #258647;
  border-radius: 4px;
  font-weight: 600;
  font-size: 24px;
  padding: 5px 15px;
  background-color: #258647;
  color: #FFFFFF;
  margin: 15px auto auto auto;
  max-width: 200px;
  text-decoration: none;
  transition: background-color .25s, color .25s;
}

.print-at-home-cta:hover {
  background-color: #FFFFFF;
  color: #000000;
  transition: background-color .25s, color .25s;
}


.golden-pepper-edition-container {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}

.chili-images {
  display: flex;
  justify-content: center;
}

.chili-mascot-inline {
  margin-left: 30px;
}

.chili-mascot-inline:first-child {
  margin-left: 0;
}

.golden-pepper-edition-left {
  font-size: 30px;
  width: 50%;
}

.golden-pepper-edition-text {
  font-weight: 600;
}

.golden-pepper-edition-image {
  margin: 0 auto;
}

.golden-pepper-edition-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.golden-pepper-cta {
  border: solid 2px #e6c034;
  border-radius: 4px;
  font-weight: 600;
  font-size: 24px;
  padding: 5px 15px;
  background-color: #e6c034;
  color: #000000;
  margin: auto auto auto auto;
  max-width: 200px;
  text-decoration: none;
  transition: background-color .25s;
}

.golden-pepper-cta:hover {
  transition: background-color .25s;
  background-color: #FFFFFF;
}

.how-to-play-header {
  margin: 0;
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-to-play-burning-img {
  height: 75px;
}

.how-to-play-text {
  font-size: 32px;
  font-weight: 600;
}

.how-to-play-link {
  color: #8c3d75;
  font-weight: 700;
}

@media (max-width: 768px) {
  .content-row-header-container {
    flex-direction: column;
  }

  .content-row-header {
    font-size: 32px;
  }

  .content-row-subheader {
    font-size: 24px;
  }

  .what-is-heated {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .what-is-heated-messaging {
    font-size: 20px;
  }

  .heated-writeup-paragraph {
    font-size: 20px;
  }

  .what-is-heated-left {
    width: 100%;
    margin-bottom: 15px;
  }

  .what-is-heated-right {
    width: 100%;
    justify-content: center;
  }

  .print-at-home-editions {
    flex-direction: column;
  }

  .print-at-home-edition-header-image-left {
    display: none;
  }

  .print-at-home-edition-header {
    font-size: 32px;
  }


  .golden-pepper-edition-container {
    flex-direction: column;
  }

  .golden-pepper-edition-left {
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .print-at-home-edition {
    margin-bottom: 45px;
  }

  .low-ink-edition {
    margin-bottom: 15px;
  }


  .golden-pepper-edition-right {
    /* flex-direction: column-reverse; */
    width: 100%;
  }

  .golden-pepper-cta {
    margin-top: 15px;
  }

  .how-to-play-image {
    width: 100%;
    height: auto;
    max-width: 520px;
  }

}