.footer-container {
    background-color: #000000;
    color: #FFFFFF;
    padding: 15px;
    border-top: 1px #555555 solid;
}

.footer {
    max-width: 1140px;
    padding: 0 15px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    /* font-size: 24px; */
    align-items: center;
}

