.rules-header {
  font-weight: 700;
}

.rules-contents {
  height: 90vh;
  font-size: 30px;
  text-align: center;
  max-width: 1140px;
  margin: 30px auto;
  padding: 0 30px;
}

.rules-link {
  color: #8c3d75;
  font-weight: 600;
}

.how-to-play-video-container {
  display: flex;
  width: 700px;
  margin: auto;
}

.how-to-play-video {
  height: 400px;
  margin: auto;
  flex-grow: 1;
}

@media (max-width: 815px) {

  .how-to-play-video-container {
    width: auto;
  }

  .how-to-play-video {
    height: 400px;
  }

  h1 {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .how-to-play-video {
    height: 300px;
  }
}

@media (max-width: 475px) {
  .how-to-play-video {
    height: 240px;
  }
}