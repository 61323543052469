.rules-container {
  padding: 30px;
  margin: auto;
  max-width: 1140px;
  text-align: left;
  font-size: 18px;
}

.rules-header {
  margin: 0;
  font-size: 50px;
  text-align: center;
}

.table-of-contents-container {
  text-align: left;
}

.rules-section-header {
  color: rgba(43, 137, 71, 1);
  font-size: 32px;
  font-weight: 600;
}

.table-of-contents-header {
  margin-bottom: 5px;
}

.table-of-contents-section-link  {
  color: #000000;
  font-weight: 600;
}

.table-of-contents-subsection-link {
  color: #000000;
  font-weight: 600;
}

.table-of-contents-subsection-link-text {
  margin-left: 15px;
}

.card-contents-small-header {
  margin: 5px 0;
}

.card-list-item {
  margin: 0;
}

.card-list-item-bold {
  font-weight: 600;
}

.how-to-win-text {
  margin: 0;
}

.card-explanation-container {
  display: flex;
  margin-bottom: 60px;
}

.card-explanation-image-container {
  margin: 0 0 0 auto;
}

.card-explanation-image {
  border-radius: 8px;
}

.card-explanation-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 30px;
  white-space: pre-line;
}

.black-special-card-text {
  font-weight: 700;
}

.white-special-card-text {
  background-color: rgba(0, 0, 0, .9);
  color: #FFFFFF;
  font-weight: 600;
  padding: 0 2px;
}

.underline {
  text-decoration: underline;
}

.playing-the-game-you-have-two-options {
  margin: 0;
}

.playing-the-game-subtitle-indent {
  margin: 5px 0 5px 15px;
}

.suggested-house-rules-list-item {
  margin: 5px 0;
}

.base-game-mode-link-to-cards {
  color: blue;
}

@media (max-width: 500px) {
  .card-explanation-container {
    flex-direction: column-reverse;
  }

  .card-explanation-text {
    margin-right: 0;
  }

  .card-explanation-image-container {
    margin: 0 auto 10px auto;
  }
}


@media (max-width: 768px) {
  .rules-header {
    font-size: 32px;
  }
}
